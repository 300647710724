
import { Component, Vue } from 'vue-property-decorator'
import { apiNoticeDetail, apiNoticeSet } from '@/api/setting'
@Component
export default class DESSJ extends Vue {
    id: any = 1
    detail: any = {
        // 系统通知
        system_notice: {
            status: false,
            content: '',
            title: ''
        },
        // 短信通知
        sms_notice: {
            content: '',
            status: true,
            template_id: ''
        },
        // 微信模板
        oa_notice: {
            first: '',
            status: false,
            name: '',
            remark: '',
            template_id: '',
            template_sn: '',
            tpl: []
        },
        // 微信小程序
        mnp_notice: {
            status: false,
            name: '',
            template_id: '',
            template_sn: '',
            tpl: []
        }
    }

    // 提交保存
    onSubmit() {
        const params = {
            id: this.id,
            template: [
                {
                    type: 'system',
                    ...this.detail.system_notice
                },
                {
                    type: 'sms',
                    ...this.detail.sms_notice
                },
                {
                    type: 'oa',
                    ...this.detail.oa_notice
                },
                {
                    type: 'mnp',
                    ...this.detail.mnp_notice
                }
            ]
        }

        apiNoticeSet({ ...params })
            .then(res => {
                this.$router.go(-1)
                this.$message.success('设置成功!')
            })
            .catch(() => {
                this.$message.error('数据请求失败，刷新重载!')
            })
    }

    // 获取详情
    getNoticeDetail() {
        apiNoticeDetail({ id: this.id })
            .then((res: any) => {
                this.detail = res
            })
            .catch(() => {
                this.$message.error('数据请求失败，刷新重载!')
            })
    }

    // 新增微信模板字段
    onAddModeField() {
        this.detail.oa_notice.tpl.push({
            tpl_name: '',
            tpl_keyword: '',
            tpl_content: ''
        })
    }

    // 新增微信小程序模板字段
    onAddWeChatModeField() {
        this.detail.mnp_notice.tpl.push({
            tpl_name: '',
            tpl_keyword: '',
            tpl_content: ''
        })
    }

    created() {
        this.id = this.$route.query.id
        this.id && this.getNoticeDetail()
    }
}
